import React, { useContext } from 'react';
import { graphql, PageProps } from 'gatsby';
import CompanyDetail from '@/components/Company/CompanyDetail';
import { Context } from '@/components/Layout';

const CompanyPage: React.FC<PageProps<GatsbyTypes.CompanyPageQuery>> = ({
  data,
}) => {
  const loading = useContext(Context);
  return !loading && <CompanyDetail data={data} />;
};

export default CompanyPage;

export const query = graphql`
  query CompanyPage($CompanyPageId: String!) {
    microcmsCompany(companyId: { eq: $CompanyPageId }) {
      id
      companyId
      title
      titleEn
      description
      keyword
      body {
        plain
        rich
      }
    }
  }
`;
