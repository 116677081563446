// ■コンポーネント説明
// 会社情報詳細表示用コンポーネント

import React from 'react';
import SEO from '@/components/seo';
import CDNReplace from '@/components/CDNReplace';
import CompanyNavigation from '@/components/Company/CompanyNavigation';
import UIBreadcrumb from '@/components/UI/UIBreadcrumb';

import * as pageHeader from '@/styles/object/page-header.module.scss';

export interface CompanyDetailProps {
  data: GatsbyTypes.CompanyPageQuery;
}

const CompanyDetail: React.FC<CompanyDetailProps> = (props, state) => {
  const { data } = props;

  return (
    <>
      <SEO
        title={`${data.microcmsCompany.title} | 会社情報`}
        description={data.microcmsCompany.description}
        keyword={data.microcmsCompany.keyword}
      />
      <div className="page-wrap --company">
        <div className="page-wrap__wave" />
        <div className="page-wrap__bg" />
        <header className={pageHeader.page_header}>
          <h1 className={pageHeader.page_header__title}>
            <small className={pageHeader.page_header__head_text}>
              {data.microcmsCompany.title}
            </small>
            <div className={pageHeader.page_header__text}>
              {data.microcmsCompany.titleEn}
            </div>
          </h1>
        </header>
        <div className="page-contents-box">
          <div
            className={
              data.microcmsCompany.companyId +
              (data.microcmsCompany.companyId === `sustainability`
                ? ` editor`
                : ``)
            }
          >
            <section className={`${data.microcmsCompany.companyId}__main`}>
              {data.microcmsCompany.body &&
                data.microcmsCompany.body.map((body) => {
                  let output = ``;
                  if (body.rich) {
                    output = body.rich;
                  }
                  if (body.plain) {
                    output = body.plain;
                  }
                  return (
                    // eslint-disable-next-line react/no-danger
                    <div
                      key={output}
                      dangerouslySetInnerHTML={{ __html: CDNReplace(output) }}
                    />
                  );
                })}
            </section>
          </div>
        </div>
      </div>
      <CompanyNavigation current={data.microcmsCompany.titleEn} />
      <UIBreadcrumb
        UpperItem={[[`About Netcom`, `/company`]]}
        CurrentItem={data.microcmsCompany.titleEn}
      />
    </>
  );
};

export default CompanyDetail;
